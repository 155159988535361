import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useAtom } from 'jotai';
import { UPDATE_CART_ITEM } from 'graphql/mutations';
import { cartAtom, cartSidebarAtom } from 'global/store';
import removeFromCartEvent from 'lib/Google/events/removeFromCart.gtag';

export default function useCart() {
  const [cart, setCart] = useAtom(cartAtom);
  const [open, setOpen] = useAtom(cartSidebarAtom);

  const [updatedItem, setUpdatedItem] = useState(null);

  const [updateCartItem, { data }] = useMutation(UPDATE_CART_ITEM, {
    variables: {
      cartId: cart?.id,
      uid: updatedItem?.uid,
      quantity: updatedItem?.quantity
    }
  });

  

  const updateItem = (item, quantity) => {
    setUpdatedItem({
      ...item,
      quantity
    });
  };

  useEffect(() => {
    if (updatedItem) {
      updateCartItem();
      
      if (updatedItem && updatedItem.quantity === 0) {
        removeFromCartEvent(updatedItem?.product);
      }

      data?.cart && setCart(data?.cart);
    }
  }, [updatedItem, data?.cart]);

  return { cart, open, setOpen, updateItem };
}
