import { forwardRef } from 'react';
import { useRouter } from 'next/router';
import { useUpdateAtom } from 'jotai/utils';
import { accountFlyoutAtom } from 'global/store';
import { accountItems } from 'global/data';
import useLogout from 'hooks/auth/useLogout';
import styled from '@xstyled/styled-components';
import Button from '@/UI/Button';
import { LogOut } from '@/UI/Icons/Account';
import { Rebuild } from '@/UI/Icons/Account';
import { useAtom } from 'jotai';
import { customerAtom } from 'global/store';
import { builders } from 'utils/builders';
import { useTranslation, UseTranslation } from 'next-i18next';

const Options = styled.sectionBox`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 4rem 0 0 1rem;
  z-index: 4;

  @media (min-width: desktop) {
    width: 208px;
    min-height: 242px;
    padding: 2.5rem 1.5rem;
    position: absolute;
    right: 70;
    top: 50;
    border-radius: 15px;
    box-shadow: 0px 0px 12px 2px rgba(196, 196, 196, 1);
  }

  button {
    padding: 0;
    display: flex;

    @media (max-width: desktop) {
      align-items: center;
    }

    &:hover,
    &:active {
      color: teal-400;
    }

    &:last-of-type {
      @media (max-width: desktop) {
        padding-top: 2rem;
      }
    }

    span {
      margin-right: 1rem;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
`;

const AccountMenu = forwardRef(({ toggleMenu }, ref) => {
  const router = useRouter();
  const {t} = useTranslation();
  const lang = router.locale ? router.locale : 'et';

  const setOpen = useUpdateAtom(accountFlyoutAtom);
  const logout = useLogout(toggleMenu);

  const navigate = path => {
    setOpen(false);
    router.push(path);
  };
  const [customer] = useAtom(customerAtom);

  return (
    <Options ref={ref} spaceY={{ _: '2rem', desktop: '1.25rem' }}>
      {accountItems.map(item => {
        return (
          <Button key={item.path} onClick={() => navigate(item.path)}>
            <span role="img" aria-label="Avatar">
              {item.icon}
            </span>
            {t('my')} {lang === 'en' ? item.titleEN : item.title}
          </Button>
        );
      })}
      {builders.includes(customer?.email) ? (
        <Button onClick={() => navigate('/builder')}>
          <span role="img" aria-label="Exit sign">
            {Rebuild}
          </span>
          Rebuild
        </Button>
      ) : null}

      <Button onClick={logout}>
        <span role="img" aria-label="Exit sign">
          {LogOut}
        </span>
        {t('header.logout')}
      </Button>
    </Options>
  );
});

AccountMenu.displayName = 'AccountMenu';

export default AccountMenu;
