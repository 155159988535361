import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { useAtom } from 'jotai';
import { localeAtom } from 'global/store';
import Link from 'next/link';
import useCreateLocaleCart from 'hooks/cart/useCreateLocaleCart';
import styled, { css, th, useViewportWidth } from '@xstyled/styled-components';
import { VerticalChevron } from '@/UI/Icons';
import { Estonian, English, Lithuania } from '@/UI/Icons/Header';
import { useTranslation } from 'next-i18next';
import useCartData from 'hooks/cart/useCartData';

const transformArrow = css`
  transform: rotate(-180deg);
  transition: 0.4s ease-in-out;
  top: 7;

  @media (min-width: desktop) {
    top: 5;
    right: 8;
  }
`;

const transformHeight = css`
  height: 66px;
  transition: height 0.5s ease-in-out;
`;

const Wrapper = styled.div`
  position: relative;
  width: 130px;
  margin-bottom: 6rem;

  &:hover {
    span.arrow {
      ${transformArrow}
    }
  }

  span.arrow {
    position: absolute;
    cursor: pointer;
    top: 3;
    right: 10;
    transition: 0.4s ease-in-out;

    @media (min-width: desktop) {
      top: 4;
      right: 8;
    }

    svg {
      transform: scale(1);
    }

    ${({ open }) =>
      open &&
      css`
        ${transformArrow}
      `}
  }

  @media (min-width: desktop) {
    margin-left: 4rem;
    margin-bottom: 0;
  }
`;

const FormControl = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  border: 2px solid ${th.color('teal-100')};
  border-radius: 12px;
  width: 130px;
  height: 33px;
  padding: 0 0.4rem;
  line-height: 1.9;
  overflow: hidden;
  cursor: pointer;

  ${Wrapper}:hover & {
    ${transformHeight}
  }

  ${({ open }) =>
    open &&
    css`
      ${transformHeight}
    `}

  ${({ selectedLang }) =>
    selectedLang === 'en' &&
    css`
      span:nth-of-type(2) {
        order: -1;
      }
    `}

    ${({ selectedLang }) =>
    selectedLang === 'et' &&
    css`
      span:nth-of-type(3) {
        order: -1;
      }
    `}
`;

const MenuItem = styled.span`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;

export default function LanguageSelector() {
  const { t } = useTranslation();

  const router = useRouter();
  const viewportWidth = useViewportWidth();
  const cookies = new Cookies();
  const [open, setOpen] = useState(false);
  const [locale, setLocale] = useAtom(localeAtom);

  const cart = useCartData()
  const changeLocale = locale => {
    setLocale(locale);
    /*
    if (locale === 'en') {
      createCart();
    }
    */
    router.push(router.asPath, undefined, { locale });
  };

  useEffect(() => {
    cookies.set('BALTBABY_LOCALE', router.locale, { path: '/' });
  }, [router.locale]);

  return (
    <Wrapper onClick={() => viewportWidth < 1024 && setOpen(!open)} open={open}>
      <span className="arrow" role="img" aria-label="Language menu arrow">
        <VerticalChevron />
      </span>
      <FormControl open={open} selectedLang={router.locale}>
        
        <MenuItem aria-label="Select language">
          <div onClick={() => changeLocale('et')}>
            {Estonian}
            <span>{t('lang.estonian')}</span>
          </div>
        </MenuItem>
      
        
        <MenuItem aria-label="Select language">
          <div onClick={() => changeLocale('en')}>
            {English}
            <span>{t('lang.english')}</span>
          </div>
        </MenuItem>
      </FormControl>
    </Wrapper>
  );
}