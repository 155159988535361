import styled from '@xstyled/styled-components';

export const Container = styled.divBox`
  position: relative;
  width: 100%;
  margin: 0 auto;

  @media (min-width: desktop) {
    max-width: 980px;
  }

  @media (min-width: lg_desktop) {
    max-width: 1140px;
  }
`;
