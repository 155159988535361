import Link from 'next/link';
import Image from 'next/image';
import styled from '@xstyled/styled-components';
import getProductPrice from 'utils/getProductPrice';
import DiscountPercentBadge from '@/UI/ProductCard/DiscountPercentBadge';
import { CrossOut } from '@/UI/utils';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import getProductImage from 'utils/getProductImage';

const ResultRow = styled.divBox`
  position: relative;
  display: grid;
  align-items: start;
  grid-template-columns: 80px 200px 1fr;
  padding-top: 0.5rem;
  border-bottom: 1px solid #e3e9ec;

  p {
    padding: 0.75rem 0.5rem 0 1rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .newPrice {
    padding: 0.5rem 1rem 0 0;
    font-weight: 500;
    font-size: small;
    color: teal-200;
    white-space: nowrap;
  }

  .originalPrice {
    padding: 0;
    position: relative;
    color: blue-200;

    &:after {
      ${CrossOut};
    }
  }

  &:hover p {
    color: gray-100;
  }

  @media (max-width: 454.98px) {
    grid-template-columns: 1fr 160px 1fr;

    p,
    .newPrice,
    .originalPrice {
      font-size: xxsmall;
    }

    .newPrice {
      padding: 0.65rem 1rem 0 0;
    }
  }

  @media (min-width: 455px) and (max-width: desktop) {
    grid-template-columns: 100px 1fr 1fr;
  }
`;

export default function SearchQueryResults({
  searchResults,
  slice,
  imageSize,
  setShowDropdown,
  showDiscountBadge,
  ...rest
}) {
  return (
    <>
      {searchResults?.slice(0, slice).map(result => {
        const { priceValue, discount } = getProductPrice(result?.price_range);
        const image = getProductImage({
          mainImage: result?.image,
          gallery: result?.media_gallery
        });

        return (
          <Link href={`/toode/${result?.url_key}`} key={result?.url_key}>
            <a onClick={() => setShowDropdown(false)}>
              <ResultRow {...rest}>
                {discount?.percentNumber > 0 && (
              
                  <DiscountPercentBadge
                    percent={
                      '-' +
                      Math.round(
                        Number(
                          discount?.percent
                            ?.replace('-', '')
                            .replace(' ', '')
                            .replace('kuni', '')
                            .replace('%', '')
                        )
                      ) +
                      '%'
                    }
                  />
                )}
                <Image
                  src={image?.url}
                  alt={image?.label}
                  width={imageSize.width}
                  height={imageSize.height}
                  layout="fixed"
                  objectFit="contain"
                />
                <p>{result?.name}</p>
                <FlexBox spaceX={8} flexWrap="wrap">
                  <span className="newPrice">{priceValue}</span>
                  <span className="originalPrice">{discount?.value}</span>
                </FlexBox>
              </ResultRow>
            </a>
          </Link>
        );
      })}
    </>
  );
}
