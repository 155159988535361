export default function getPrice(rangePrice, price) {
  let cost;

  if (rangePrice) {
    cost = {
      regularPrice: rangePrice?.regular_price.value,
      finalPrice: rangePrice?.final_price.value,
      discount: rangePrice?.discount.percent_off
    };
  } else {
    cost = price?.regularPrice.amount.value;
  }

  return cost;
}
