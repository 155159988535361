import styled, { Box } from '@xstyled/styled-components';
import { Container } from '@/UI/Layout/Container';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';


const Content = styled(Container)`
  display: flex;
  justify-content: flex-end;

  div p {
    font-size: 0.875rem;
    color: #a2aaad;

    span {
      margin-right: 10px;

      svg {
        overflow: visible;

        &#phone-icon {
          transform: translateY(20%);
        }

        &#mail-icon {
          transform: scale(1.2);
        }
      }
    }

    a {
      font-size: inherit;
      color: inherit;

      &:hover {
        color: black;
      }
    }
  }
`;

export default function ContactInfo() {
  const { t } = useTranslation();

  return (
    <Box
      as="section"
      display={{ _: 'none', desktop: 'block' }}
      position="absolute"
      top={0}
      left={0}
      w="100%"
      py={12}
      bg="white"
    >
      <Content>
       
        <FlexBox spaceX={32}>
          <p>
            <span role="img" aria-label="Telephone">
              <Phone />
            </span>
            <a href="tel:+37256988255"> +372 5698 8255</a> {t('header.times')}
          </p>
          <p>
            <span role="img" aria-label="Mail letter">
              <Mail />
            </span>
            <a href="mailto:klienditeenindus@baltbaby.com">
              klienditeenindus@baltbaby.com
            </a>
          </p>
        </FlexBox>
      </Content>
    </Box>
  );
}

const Phone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.252"
      height="14.252"
      viewBox="0 0 14.252 14.252"
      id="phone-icon"
    >
      <path
        d="M2,8.417V4.736A.736.736,0,0,1,2.736,4H6.417a.736.736,0,0,1,.736.736V7.681a.736.736,0,0,1-.736.736H4.945a5.89,5.89,0,0,0,5.89,5.89V12.834a.736.736,0,0,1,.736-.736h2.945a.736.736,0,0,1,.736.736v3.681a.736.736,0,0,1-.736.736H10.834A8.834,8.834,0,0,1,2,8.417Z"
        transform="translate(-1.5 -3.5)"
        fill="none"
        stroke="#a9bcc4"
        strokeWidth="1"
      />
    </svg>
  );
};

const Mail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.732"
      height="9.336"
      viewBox="0 0 11.732 9.336"
      id="mail-icon"
    >
      <path
        d="M3.006,5.49a.652.652,0,0,1,.652-.652H14.08a.652.652,0,0,1,.652.652V12.87a1.3,1.3,0,0,1-1.3,1.3H4.3A1.3,1.3,0,0,1,3,12.87V5.7a.657.657,0,0,1,.006-.091ZM4.3,6.939V12.87h9.125V6.939l-3.18,3.18a1.955,1.955,0,0,1-2.765,0ZM5.328,6.12H12.4L9.327,9.2a.652.652,0,0,1-.922,0Z"
        transform="translate(-3 -4.838)"
        fill="#a9bcc4"
        fillRule="evenodd"
      />
    </svg>
  );
};
