import Link from 'next/link';
import { useAtomValue } from 'jotai/utils';
import { authAtom, customerAtom } from 'global/store';
import { Box, useViewportWidth } from '@xstyled/styled-components';
import { Logo } from '@/UI/Icons';
import { Avatar, ShoppingCart } from '@/UI/Icons/Header';
import ConditionalWrapper from '@/UI/Primitives/ConditionalWrapper';
import { IconLink, ProductsInCart } from './styles';
import { useTranslation } from 'next-i18next';

export const LogoLink = () => {
  return (
    <Link href="/">
      <a aria-label="Go to homepage">
        <span role="img" aria-label="Baltbaby logo">
          <Logo />
        </span>
      </a>
    </Link>
  );
};

export const CartButton = ({ quantity, isActive, onClick }) => {
  const { t } = useTranslation();

  return (
    <IconLink
      role="button"
      aria-label="Toggle shopping cart"
      onClick={onClick}
      isActive={isActive}
    >
      <span role="img" aria-label="Shopping cart">
        {ShoppingCart}
      </span>
      {t('cart.shopping_cart')}
      {quantity > 0 && <ProductsInCart>{quantity}</ProductsInCart>}
    </IconLink>
  );
};

export const AccountButton = ({ onClick, isActive }) => {
  const { t } = useTranslation();

  const viewportWidth = useViewportWidth();
  const isDesktop = viewportWidth >= 1024;

  const { token: isAuth } = useAtomValue(authAtom);
  const customer = useAtomValue(customerAtom);

  return (
    <IconLink
      role="button"
      aria-label="Toggle account menu"
      onClick={onClick}
      isActive={isActive || isAuth}
    >
      <span role="img" aria-label="Avatar">
        {Avatar}
      </span>
      <ConditionalWrapper
        condition={isDesktop}
        wrap={children => (
          <Box as="span" position="relative" bottom="0.4">
            {children}
          </Box>
        )}
      >
        <Box as="span">
          {isAuth ? (customer ? customer.firstname : '. . .') : t('auth.login')}
        </Box>
      </ConditionalWrapper>
    </IconLink>
  );
};
