import getFormattedPrice from './getFormattedPrice';

export default function getProductPrice(prices) {
  const minimum = prices?.minimum_price;
  const minimumFinalValue = minimum?.final_price?.value;

  const minimumRegularValue = minimum?.regular_price?.value;

  const maximum = prices?.maximum_price;

  const maximumFinalValue = maximum?.final_price?.value;
  const maximumRegularValue = maximum?.regular_price?.value;

  const priceRange = minimumFinalValue !== maximumFinalValue;

  const minimumPrice = getFormattedPrice(minimumFinalValue);
  const maximumPrice = getFormattedPrice(maximumFinalValue);

  const priceValue = priceRange ? `al. ${minimumPrice}` : maximumPrice;

  const discount = priceRange
    ? minimum?.discount?.amount_off > 0
      ? {
          value: `al. ${getFormattedPrice(minimumRegularValue)}`,
          percent: `kuni -${minimum.discount?.percent_off}%`,
          percentNumber: maximum?.discount?.percent_off
        }
      : null
    : maximum?.discount?.amount_off > 0
    ? {
        value: getFormattedPrice(maximumRegularValue),
        percent: `-${maximum?.discount?.percent_off}%`,
        percentNumber: maximum?.discount?.percent_off
      }
    : null;

  return { priceValue, discount };
}
