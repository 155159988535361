export const EmptyCart = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="175.427"
    height="91.067"
    viewBox="0 0 175.427 91.067"
  >
    <g
      id="Group_1347"
      data-name="Group 1347"
      transform="translate(-97.573 -358.933)"
      fill="#52c1d3"
    >
      <g
        id="Group_242"
        data-name="Group 242"
        transform="translate(97.573 358.933)"
        opacity="0.12"
      >
        <path
          id="Path_2"
          data-name="Path 2"
          d="M84.833,74.579H41.841a13,13,0,0,1-12.813-11.11l-6.07-38.155a6.631,6.631,0,0,0-6.531-5.71,3.177,3.177,0,0,1,0-6.354A13,13,0,0,1,29.24,24.364l6.07,38.155a6.631,6.631,0,0,0,6.531,5.705H84.833a6.543,6.543,0,0,0,6.434-5.155l5.214-19.133a2.611,2.611,0,0,0-.563-2.139A2.7,2.7,0,0,0,93.8,40.782H50.312a3.177,3.177,0,0,1,0-6.354H93.8a9.066,9.066,0,0,1,8.848,11.034L97.434,64.591A12.82,12.82,0,0,1,84.833,74.579Z"
          transform="translate(-13.25 -13.25)"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M29.9,52.546A11.648,11.648,0,1,1,41.546,40.9,11.658,11.658,0,0,1,29.9,52.546Zm0-16.943A5.295,5.295,0,1,0,35.193,40.9,5.3,5.3,0,0,0,29.9,35.6Z"
          transform="translate(2.929 38.521)"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M37.9,52.546A11.648,11.648,0,1,1,49.546,40.9,11.658,11.658,0,0,1,37.9,52.546Zm0-16.943A5.295,5.295,0,1,0,43.193,40.9,5.3,5.3,0,0,0,37.9,35.6Z"
          transform="translate(28.814 38.521)"
        />
      </g>
      <g
        id="Group_241"
        data-name="Group 241"
        transform="translate(142.382 358.933)"
        opacity="0.36"
      >
        <path
          id="Path_2-2"
          data-name="Path 2"
          d="M84.833,74.579H41.841a13,13,0,0,1-12.813-11.11l-6.07-38.155a6.631,6.631,0,0,0-6.531-5.71,3.177,3.177,0,0,1,0-6.354A13,13,0,0,1,29.24,24.364l6.07,38.155a6.631,6.631,0,0,0,6.531,5.705H84.833a6.543,6.543,0,0,0,6.434-5.155l5.214-19.133a2.611,2.611,0,0,0-.563-2.139A2.7,2.7,0,0,0,93.8,40.782H50.312a3.177,3.177,0,0,1,0-6.354H93.8a9.066,9.066,0,0,1,8.848,11.034L97.434,64.591A12.82,12.82,0,0,1,84.833,74.579Z"
          transform="translate(-13.25 -13.25)"
        />
        <path
          id="Path_3-2"
          data-name="Path 3"
          d="M29.9,52.546A11.648,11.648,0,1,1,41.546,40.9,11.658,11.658,0,0,1,29.9,52.546Zm0-16.943A5.295,5.295,0,1,0,35.193,40.9,5.3,5.3,0,0,0,29.9,35.6Z"
          transform="translate(2.929 38.521)"
        />
        <path
          id="Path_4-2"
          data-name="Path 4"
          d="M37.9,52.546A11.648,11.648,0,1,1,49.546,40.9,11.658,11.658,0,0,1,37.9,52.546Zm0-16.943A5.295,5.295,0,1,0,43.193,40.9,5.3,5.3,0,0,0,37.9,35.6Z"
          transform="translate(28.814 38.521)"
        />
      </g>
      <g
        id="Group_240"
        data-name="Group 240"
        transform="translate(183.382 358.933)"
      >
        <path
          id="Path_2-3"
          data-name="Path 2"
          d="M84.833,74.579H41.841a13,13,0,0,1-12.813-11.11l-6.07-38.155a6.631,6.631,0,0,0-6.531-5.71,3.177,3.177,0,0,1,0-6.354A13,13,0,0,1,29.24,24.364l6.07,38.155a6.631,6.631,0,0,0,6.531,5.705H84.833a6.543,6.543,0,0,0,6.434-5.155l5.214-19.133a2.611,2.611,0,0,0-.563-2.139A2.7,2.7,0,0,0,93.8,40.782H50.312a3.177,3.177,0,0,1,0-6.354H93.8a9.066,9.066,0,0,1,8.848,11.034L97.434,64.591A12.82,12.82,0,0,1,84.833,74.579Z"
          transform="translate(-13.25 -13.25)"
        />
        <path
          id="Path_3-3"
          data-name="Path 3"
          d="M29.9,52.546A11.648,11.648,0,1,1,41.546,40.9,11.658,11.658,0,0,1,29.9,52.546Zm0-16.943A5.295,5.295,0,1,0,35.193,40.9,5.3,5.3,0,0,0,29.9,35.6Z"
          transform="translate(2.929 38.521)"
        />
        <path
          id="Path_4-3"
          data-name="Path 4"
          d="M37.9,52.546A11.648,11.648,0,1,1,49.546,40.9,11.658,11.658,0,0,1,37.9,52.546Zm0-16.943A5.295,5.295,0,1,0,43.193,40.9,5.3,5.3,0,0,0,37.9,35.6Z"
          transform="translate(28.814 38.521)"
        />
      </g>
    </g>
  </svg>
);
