import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { isIOS } from 'react-device-detect';
import { useAtom } from 'jotai';
import { accountFlyoutAtom, authAtom, authModalAtom } from 'global/store';
import { Box, useTheme, useViewportWidth } from '@xstyled/styled-components';
import styled from 'styled-components';
import getFormattedPrice from 'utils/getFormattedPrice';
import useClickOutside from 'hooks/UI/useClickOutside';
import useLockBodyScroll from 'hooks/UI/useLockBodyScroll';
import useCart from 'hooks/cart/useCart';
import Products from './Products';
import { AccountButton, CartButton, LogoLink } from '@/Header/Navbar/Links';
import Dismiss from '@/UI/Dismiss';
import CouponCode from '@/UI/CouponCode';
import PrimaryButton from '@/UI/Button/Variants/Primary';
import MonochromeButton from '@/UI/Button/Variants/Monochrome';
import { SectionTitle } from '@/UI/Primitives/Headings';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import Spacer from '@/UI/Primitives/Spacer';
import { Checkmark } from '@/UI/Icons';
import { EmptyCart } from '@/UI/Icons/Cart';
import { MobileNav } from '@/Header/Navbar/styles';
import { CartContainer, PriceInfo } from './styles';
import { useTranslation } from 'next-i18next';

import { useQuery } from '@apollo/client';
import { GET_VARIATION_IMAGE } from 'graphql/queries';
import axios from 'axios';

const getCartImage = async (mainImage, sku, variableUid, lang) => {
  let image = { label: mainImage?.label };

  const res = await axios.get(
    `https://do.baltbaby.com/graphql?query=query%20GetProduct(%24sku%3A%20String!)%20%7B%0A%20%20products(filter%3A%20%7Bsku%3A%20%7Beq%3A%20%24sku%7D%7D)%20%7B%0A%20%20%20%20items%20%7B%0A%20%20%20%20%20%20...%20on%20ConfigurableProduct%20%7B%0A%20%20%20%20%20%20%20%20variants%20%7B%0A%20%20%20%20%20%20%20%20%20%20product%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20uid%0A%20%20%20%20%20%20%20%20%20%20%20%20image%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20__typename%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%20%20__typename%0A%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20__typename%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20__typename%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20__typename%0A%20%20%20%20%7D%0A%20%20%20%20__typename%0A%20%20%7D%0A%7D&operationName=GetProduct&variables=%7B%22sku%22%3A%22${sku}%22%7D`,
    {
      headers: {
        store: lang
      }
    }
  );
  const data = res.data.data;
  if (data && data.products) {
    if (data.products.items.length > 0) {
      const filteredItem = data.products.items[0].variants.filter(
        el => el.product.uid === variableUid
      );
      if (filteredItem.length > 0) {
        image = { ...image, url: filteredItem[0].product.image.url };
      }
    }
  }
  return image;
};

export default function Cart({ isHovered }) {
  const { t } = useTranslation();
  const router = useRouter();
  const lang = router.locale ? router.locale : 'et';

  const viewportWidth = useViewportWidth();
  const theme = useTheme();

  const cartRef = useRef(null);
  const { cart, open, setOpen, updateItem } = useCart();
  const [cartWithImages, setCartWithImages] = useState();

  useEffect(async () => {
    if (cart?.items.length > 0) {
      for (const item of cart.items) {
        const { sku, image: mainImage } = item?.product;

        const variableUid = item?.configured_variant?.uid;
        if (variableUid) {
          const image = await getCartImage(mainImage, sku, variableUid, lang);
          const updatedCart = cart

         const singleItemIndex = cart.items.findIndex((el)=> el.configured_variant?.uid === variableUid) 
         updatedCart.items[singleItemIndex].configured_variant['image'] = image
         setCartWithImages(updatedCart)
        }
      }
    }
  }, [cart]);

  const itemsInCart = cart?.items.length > 0;
  const totalCharge = cart?.prices.grand_total.value;
  const discount = cart?.prices.discounts;

  const [{ token: isAuth }] = useAtom(authAtom);
  const [isAccountFlyoutOpen, setAccountFlyoutOpen] =
    useAtom(accountFlyoutAtom);
  const [authModalOpen, setAuthModalOpen] = useAtom(authModalAtom);

  const toggleAccountButton = () => {
    setOpen(false);
    setTimeout(() => {
      if (isAuth) {
        setAccountFlyoutOpen(!isAccountFlyoutOpen);
      } else {
        setAuthModalOpen(!authModalOpen);
      }
    }, 1000);
  };

  const goToCheckout = () => {
    setOpen(false);
    router.push('/checkout');
  };

  useClickOutside(
    cartRef,
    () => setOpen(false),
    viewportWidth >= 1024 && !isHovered
  );

  useLockBodyScroll((open && viewportWidth < 1024) || authModalOpen);

  return (
    <CartContainer ref={cartRef} open={open}>
      <MobileNav mt={22} mb={18}>
        <Dismiss
          transform="translateY(-20%)"
          aria-label="Close shopping cart"
          onClick={() => setOpen(false)}
        />

        <LogoLink />

        <FlexBox align="flex-end" spaceX="1rem" mt="0.6rem">
          <AccountButton onClick={toggleAccountButton} />
          <CartButton
            quantity={cart?.total_quantity}
            onClick={() => setOpen(false)}
          />
        </FlexBox>
      </MobileNav>

      <Box display={{ _: 'none', desktop: 'flex' }}>
        <DismissSection>
          <Dismiss
            aria-label="Close shopping cart"
            onClick={() => setOpen(false)}
          />
          <SectionTitle
            as="span"
            role="button"
            cursor="pointer"
            onClick={() => setOpen(false)}
            fontSize="large"
            p={{ _: '0 0 2rem', desktop: '0 2rem 0.5rem' }}
          >
            {t('cart.shopping_cart')}
          </SectionTitle>
        </DismissSection>
      </Box>

      <FlexBox justify={itemsInCart ? 'flex-start' : 'center'}></FlexBox>

      {!itemsInCart ? (
        <FlexBox
          dir="column"
          align="center"
          justify="space-around"
          h="100vh"
          pb={isIOS ? '8rem' : '4rem'}
        >
          <Box spaceY="2rem">
            <Box
              as="span"
              position="relative"
              right={12}
              role="img"
              aria-label="Empty cart"
            >
              {EmptyCart}
            </Box>
            <p>{t('cart.cart_empty')}</p>
          </Box>

          <ContinueShopping onClick={() => setOpen(false)} />
        </FlexBox>
      ) : (
        <Box>
          <Products cart={cartWithImages} updateItem={updateItem} />
          <FixedContainer>
            <PriceInfo>
              {discount && (
                <dl>
                  <dt>{t('cart.discount')}:</dt>
                  <dd>{getFormattedPrice(discount[0]?.amount.value)}</dd>
                </dl>
              )}
              <dl>
                <dt>{t('cart.total_sum')}:</dt>
                <dd className="cartTotal">{getFormattedPrice(totalCharge)}</dd>
              </dl>
              {totalCharge < 100 ? (
                <dl>
                  <dt>{t('cart.until_free_delivery')}:</dt>
                  <dd className="untilFreeDelivery">
                    {getFormattedPrice(100 - totalCharge)}
                  </dd>
                </dl>
              ) : (
                <dl>
                  <dt>{t('cart.free_delivery')}:</dt>
                  <dd role="img" aria-label="Checkmark">
                    <Checkmark stroke={theme.colors['teal-300']} width="3" />
                  </dd>
                </dl>
              )}
            </PriceInfo>
            {!cart.applied_coupons ? (
              <CouponCode cartOpen={open} />
            ) : (
              <Spacer />
            )}
            <FlexBox
              dir="column"
              align="center"
              position="relative"
              w="100%"
              pt="0.5rem"
              pl={{ _: '1.5rem', lg_tablet: '0' }}
            >
              {' '}
              <PrimaryButton
                w={{ _: '100%', lg_tablet: '65%' }}
                p="0.6rem 3rem"
                mb="0.6rem"
                onClick={goToCheckout}
              >
                {t('cart.go_to_checkout')}
              </PrimaryButton>
              <ContinueShopping onClick={() => setOpen(false)} />
            </FlexBox>
          </FixedContainer>
        </Box>
      )}
    </CartContainer>
  );
}

const ContinueShopping = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <MonochromeButton
      w={{ _: '100%', lg_tablet: '65%' }}
      p="0.6rem 2rem"
      onClick={onClick}
    >
      {t('cart.continue_shopping')}
    </MonochromeButton>
  );
};

const FixedContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  bottom: 3rem;
  width: 100%;
  justify-content: center;
  padding-right: 4rem;
  background: white;
`;

const DismissSection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
`;
