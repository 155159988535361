export default function getProductImage({ mainImage, gallery }) {
  let image = { label: mainImage?.label };

  if (mainImage?.url?.includes('placeholder') && gallery?.length) {
    image = { ...image, url: gallery[0]?.url };
  } else {
    image = { ...image, url: mainImage?.url };
  }

  return image;
}
