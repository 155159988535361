import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useQuery } from '@apollo/client';
import { cartAtom } from 'global/store';
import { CART } from 'graphql/queries';
import useCartId from './useCartId';
import { useRouter } from 'next/router';

export default function useCartData() {
  const id = useCartId();

  const router = useRouter();

  const lang = router.locale ? router.locale : 'et';

  const { data } = useQuery(CART, {
    variables: { id },
    context: {
      fetchOptions: { method: 'GET' },
      headers: { store: lang },
    },
    fetchPolicy: 'network-only'
  });
  const [cart, setCart] = useAtom(cartAtom);

  useEffect(() => {
    if (data?.cart) setCart(data?.cart);
  }, [data?.cart]);

  return cart;
}
