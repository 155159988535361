import { useEffect, useRef, useCallback } from 'react';

export default function useClickOutside(ref, handler, when = true) {
  const handlerRef = useRef(handler);

  const handleClickOutside = useCallback(event => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      handlerRef.current(event);
    }
  }, []);

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (when) {
      document.addEventListener('click', handleClickOutside, true);
      document.addEventListener('ontouchstart', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
        document.removeEventListener('ontouchstart', handleClickOutside, true);
      };
    }
  }, [ref, handler, when]);
}
