import { useEffect } from 'react';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { useMutation } from '@apollo/client';
import {
  CREATE_CUSTOMER,
  GENERATE_TOKEN,
  UPDATE_CUSTOMER
} from 'graphql/mutations';
import { useUpdateAtom } from 'jotai/utils';
import { authAtom } from 'global/store';
import { USER_TOKEN } from 'global/constants';
import usePasswordErrors from './usePasswordErrors';
import signupEvent from 'lib/Google/events/signup.gtag';

export default function useSignup({
  toggleModal,
  fields,
  setError,
  clearErrors
}) {
  const setAuth = useUpdateAtom(authAtom);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [createCustomer, { loading: signupLoading, error }] = useMutation(
    CREATE_CUSTOMER,
    {
      onCompleted: () => {
        signupEvent(); // Send signup event to Google Analytics
        generateToken({
          variables: { email: fields?.email, password: fields?.password }
        });
      }
    }
  );

  const [generateToken, { loading: loginLoading }] = useMutation(
    GENERATE_TOKEN,
    {
      onCompleted: async data => {
        const {
          generateCustomerToken: { token }
        } = data;

        // Set auth token as cookie
        const cookies = new Cookies();
        const expires = dayjs().add(1, 'd').toDate();
        cookies.set(USER_TOKEN, token, { path: '/', expires });

        // Edit new user data to enable social media login with the same e-mail
        await updateCustomer({
          variables: { input: { allow_remote_shopping_assistance: true } }
        });

        // Set token in global state
        setAuth({ token });

        // Toggle modal
        toggleModal();
      }
    }
  );

  const onSignupSubmit = data => createCustomer({ variables: data });

  const setPasswordMismatchError = field =>
    setError(field, {
      type: 'manual',
      message: 'Paroolid ei kattu'
    });

  const clearPasswordErrors = () => {
    clearErrors('password');
    clearErrors('repeatPassword');
  };

  // Set password errors manually
  usePasswordErrors(error, setError);

  // Check if password and repeated password match
  useEffect(() => {
    if (fields?.password && fields?.repeatPassword) {
      if (fields?.password !== fields?.repeatPassword) {
        setPasswordMismatchError('password');
        setPasswordMismatchError('repeatPassword');
      } else {
        clearPasswordErrors();
      }
    } else {
      clearPasswordErrors();
    }
  }, [fields?.password, fields?.repeatPassword]);

  // Validate e-mail, if already in use (error from Magento)
  useEffect(() => {
    if (error && error.message.includes('already exists')) {
      setError('email', {
        type: 'manual',
        message: 'Sellise e-mailiga kasutaja juba eksisteerib'
      });
    } else {
      clearErrors('email');
    }
  }, [error]);

  return { onSignupSubmit, loading: signupLoading || loginLoading };
}
