import { Box } from '@xstyled/styled-components';

export default function DiscountPercentBadge({ percent }) {
  return (
    <Box
      position="absolute"
      top={{ _: 10, desktop: 20 }}
      left={{ _: 10, desktop: 20 }}
      p={8}
      lineHeight={1.1}
      textAlign="center"
      color="white"
      bg="#f65a84"
      borderRadius={3}
      zIndex={2}
    >
      <Box as="span" display="inline-block" maxW={{ _: 54, desktop: 64 }}>
        {percent}
      </Box>
    </Box>
  );
}
