import Image from 'next/image';
import { useColor } from '@xstyled/styled-components';
import getCartProductData from 'utils/getCartProductData';
import getProductImage from 'utils/getProductImage';
import getCartImage from 'utils/getCartImage';

import getFormattedPrice from 'utils/getFormattedPrice';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import Button from '@/UI/Button';
import { Close } from '@/UI/Icons';
import { ProductSection, CartProduct, CartProductInfo } from './styles';

export default function Products({ cart, updateItem }) {
  const mainTeal = useColor('teal-100');
  return (
    <ProductSection>
      {cart?.items.length > 0 &&
        cart?.items.map(cartItem => {
          const { price, selectedVariants } = getCartProductData(cartItem);
          const {
            name,
            price_range,
            image: mainImage,
            media_gallery,
            uid,
            sku
          } = cartItem?.product;
          // Get product image
          let variableUid;
          let image = getProductImage({ mainImage, gallery: media_gallery });
          if (cartItem?.configured_variant) {
            variableUid = cartItem?.configured_variant?.uid;
            if (cartItem?.configured_variant?.image) {
              image = cartItem?.configured_variant?.image;
            }
          }
          // Get original price in case of discount
          const originalPrice =
            price_range?.minimum_price?.discount?.percent_off > 0 &&
            getFormattedPrice(price_range?.minimum_price?.regular_price?.value);

          return (
            <CartProduct spaceX="1rem" key={variableUid ? variableUid : uid}>
              <Image
                src={image?.url}
                alt={image?.label}
                width={90}
                height={90}
              />
              <CartProductInfo>
                <p>{name}</p>
                {selectedVariants && (
                  <span className="variant">
                    {selectedVariants?.join(' - ')}
                  </span>
                )}
                <FlexBox align="center" spaceX={10}>
                  <span className="price">{price}</span>
                  {originalPrice && (
                    <span className="originalPrice">{originalPrice}</span>
                  )}
                </FlexBox>
                <Button
                  p="0.25rem"
                  w="100px"
                  border={`1px solid ${mainTeal}`}
                  borderRadius="18px"
                >
                  <span
                    role="button"
                    aria-label="Increment product amount"
                    id="plus"
                    onClick={() => updateItem(cartItem, cartItem.quantity + 1)}
                  >
                    +
                  </span>
                  {cartItem.quantity}
                  <span
                    role="button"
                    aria-label="Decrement product amount"
                    id="minus"
                    onClick={() => updateItem(cartItem, cartItem.quantity - 1)}
                  >
                    -
                  </span>
                </Button>
              </CartProductInfo>
              <span
                role="button"
                aria-label="Remove item from cart"
                onClick={() => updateItem(cartItem, 0)}
              >
                <Close />
              </span>
            </CartProduct>
          );
        })}
    </ProductSection>
  );
}
