import { Box } from '@xstyled/styled-components';

/**
 * Spacer
 *
 * A generic component to render space between other components
 *
 * @prop {string} spaceY - prop to override space below
 * @prop {string} spaceX - prop to override space to the right
 */

export default function Spacer({ spaceY, spaceX }) {
  return (
    <Box as="span" display="block" mb={spaceY || '2rem'} mr={spaceX || 0} />
  );
}
