import styled, { css, th } from '@xstyled/styled-components';

const activeColor = 'teal-400';
const setActiveColor = css`
  ${({ isActive }) => isActive && th.color(`${activeColor}`)};
`;

export const MobileNav = styled.divBox`
  @media (min-width: desktop) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 400px) {
      transform: translateX(-60%);
    }

    @media (max-width: 350px) {
      transform: translateX(-65%);
    }

    span {
      position: relative;

      svg {
        transform: scale(0.7);

        @media (max-width: 330px) {
          transform: scale(0.5);
        }
      }
    }
  }

  span[role='button'] > svg {
    position: relative;
    top: 0.25rem;
  }
`;

export const IconLink = styled.spanBox`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: xxsmall;
  color: ${setActiveColor};
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: desktop) {
    font-size: 0.625rem;
  }

  #avatar {
    stroke: ${setActiveColor};
  }

  #cart {
    fill: ${setActiveColor};
  }

  &:hover {
    color: ${activeColor};

    #avatar {
      stroke: ${activeColor};
    }

    #cart {
      fill: ${activeColor};
    }
  }

  span {
    display: flex;
    align-items: center;
    font-size: xxsmall;

    @media (max-width: desktop) {
      font-size: 0.625rem;
    }

    svg {
      transform: scale(1.2);
      margin: 0 0 0.25rem 0.2rem;
    }
  }
`;

export const ProductsInCart = styled.span`
  position: absolute;
  bottom: 1.2rem;
  right: 0.3rem;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  background-color: pink-100;
  color: white;
  font-size: xxsmall;
  border-radius: 50%;

  @media (max-width: desktop) {
    bottom: 0.9rem;
    right: 0;
    width: 16px;
    height: 16px;
  }
`;

export const HamburgerButton = styled.button`
  display: block;
  height: 22px;
  background-color: white;
  border: none;
  padding: 0;
  margin-top: -0.4rem;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;

  span {
    position: relative;
    display: block;
    height: 3px;
    margin-bottom: 5px;
    border-radius: 3px;
    background-color: teal-300;
    z-index: 1;

    &:nth-of-type(1) {
      width: 29px;
    }

    &:nth-of-type(2) {
      width: 24px;
    }

    &:nth-of-type(3) {
      width: 12px;
    }
  }
`;
