import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { APPLY_COUPON } from 'graphql/mutations';
import useCartId from './useCartId';
import { useViewportWidth } from '@xstyled/styled-components';

export default function useCouponCode(cartOpen) {
  const viewportWidth = useViewportWidth();

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const cartId = useCartId();

  const { register, watch, reset } = useForm();
  const code = watch('code');

  const [applyCoupon, { loading }] = useMutation(APPLY_COUPON, {
    variables: { cartId, code },
    onError: setError,
    onCompleted: () => {
      toast.success('Soodustus rakendatud', {
        style: {
          position: 'relative',
          top: viewportWidth >= 1024 ? '-30px' : 0
        }
      });
    }
  });

  const toggle = () => setIsOpen(isOpen => !isOpen);

  const resetForm = () => {
    reset();
    setError(null);
  };

  useEffect(() => {
    const errorMessage = text => error?.message?.includes(text);

    if (!isOpen) resetForm();

    if (cartOpen === false) {
      resetForm();
      setIsOpen(false);
    }

    if (error) {
      if (errorMessage("isn't valid")) {
        setError({ message: 'Kood ei kehti' });
      }
      if (errorMessage('not provided') || errorMessage('missing')) {
        setError({ message: 'Sisesta kood' });
      }
    }
  }, [isOpen, cartOpen, error]);

  return { isOpen, toggle, applyCoupon, loading, register, error };
}
