import ContentLoader from 'react-content-loader';
import { FlexBox } from '../Primitives/FlexBox';

const ThreeDots = () => (
  <ContentLoader
    viewBox="0 0 400 120"
    height={120}
    width={400}
    foregroundColor="#000"
    backgroundColor="transparent"
  >
    <circle cx="150" cy="86" r="4" />
    <circle cx="194" cy="86" r="4" />
    <circle cx="238" cy="86" r="4" />
  </ContentLoader>
);

export default function Loader({ ...rest }) {
  return (
    <FlexBox justify="center" {...rest}>
      <ThreeDots />
    </FlexBox>
  );
}
