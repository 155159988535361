import { Box } from '@xstyled/styled-components';
import Button from '@/UI/Button';
import { Google, FacebookLight } from '@/UI/Icons';
import { useTranslation } from 'next-i18next';

export default function SocialMediaButton({ name, onClick }) {
  const { t } = useTranslation();

  return (
    <Button
      w={{ _: 230, mobile: 260, tablet: 300 }}
      py={8}
      spaceX={16}
      margin="0 auto"
      whiteSpace="nowrap"
      bg={name === 'Facebook' ? '#A8D5FD' : '#EC7272'}
      hoverBg={name === 'Facebook' ? '#79BFFD' : '#EE5656'}
      color="white"
      fontWeight="500"
      onClick={onClick}
    >
      <Box
        as="span"
        role="img"
        aria-label={`${name} logo`}
        position="relative"
        top={2}
      >
        {name === 'Facebook' ? FacebookLight : Google}
      </Box>
      <span>{t('ui.enter')} {name} {t('ui.with_account')}</span>
    </Button>
  );
}
