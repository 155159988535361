import { useUpdateAtom } from 'jotai/utils';
import styled from '@xstyled/styled-components';
import { accountFlyoutAtom } from 'global/store';
import AccountMenu from './AccountMenu';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import Dismiss from '@/UI/Dismiss';
import { Logo } from '@/UI/Icons';

const FlyoutContainer = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  min-height: 100vh;
  padding: 3rem 2rem;
  background-color: white;
  overflow-x: hidden;
  z-index: 11;
  animation: 0.5s in-out forwards;

  @keyframes in-out {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  #account_return {
    transform: scale(0.7);
    margin: auto;
  }
`;

export default function MobileFlyout() {
  const setOpen = useUpdateAtom(accountFlyoutAtom);

  return (
    <FlyoutContainer>
      <FlexBox align="center">
        <Dismiss
          aria-label="Close account menu"
          onClick={() => setOpen(false)}
        />
        <span
          id="account_return"
          role="button"
          aria-label="Go to homepage"
          onClick={() => setOpen(false)}
        >
          <Logo />
        </span>
      </FlexBox>
      <AccountMenu />
    </FlyoutContainer>
  );
}
