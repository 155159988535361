import Button from '..';

/**
 * MonochromeButton
 *
 * The monochrome button component
 *
 * @prop {ReactNode | ReactNodeArray} children - button content
 */

export default function MonochromeButton({ children, ...rest }) {
  return (
    <Button
      bg="transparent"
      border="1px solid black"
      hoverBorder="1px solid gray"
      color="black"
      hoverColor="gray-100"
      {...rest}
    >
      {children}
    </Button>
  );
}
