import styled from '@xstyled/styled-components';
import { CrossOut } from '@/UI/utils';

export const CartContainer = styled.aside`
  position: fixed;
  right: ${({ open }) => (open ? '0' : '-400px')};
  top: 0;
  width: 400px;
  height: 100%;
  min-height: 100vh;
  padding: 3rem 2rem 5rem;
  z-index: 11;
  overflow-y: auto;
  background-color: white;
  transition: right 0.75s;

  /* Firefox */
  scrollbar-width: none;

  /* Chrome */
  ::-webkit-scrollbar {
    display: none;
  }

  svg {
    cursor: pointer;

    &:hover #arrowThin {
      stroke: black;
    }
  }

  @media (max-width: desktop) {
    /* top: 88px; */
    width: 100%;
    right: ${({ open }) => (open ? '0' : '-100%')};
    padding: 0 1rem 10rem 1rem;
  }
`;

export const ProductSection = styled.section`
  height: 43vh;
  margin-bottom: 1rem;
  margin-right: -1rem;
  overflow-y: auto;

  @media (max-width: desktop) {
    height: 35vh;
    margin-bottom: 0;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #f0f0f0 #fff;

  /* Chrome */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #f0f0f0;
    border-radius: 10px;
  }
`;

export const CartProduct = styled.divBox`
  display: flex;
  align-items: flex-start;
  padding-bottom: 4rem;

  span svg {
    margin-top: -0.2rem;
    margin-left: 0.25rem;
    transform: scale(0.8);

    @media (max-width: desktop) {
      transform: scale(0.9);
    }
  }

  @media (max-width: desktop) {
    padding-bottom: 2rem;
  }
`;

export const CartProductInfo = styled.div`
  display: flex;
  flex-direction: column;

  p {
    width: 160px;
    line-height: 1.25;
    font-weight: 700;
  }

  .price {
    margin: 0.5rem 0;
    color: teal-200;
    font-size: small;
    font-weight: 500;
  }

  .originalPrice {
    position: relative;
    color: blue-200;
    font-weight: 500;

    &:after {
      ${CrossOut}
    }
  }

  .variant {
    font-size: xxsmall;
    color: #9f9f9f;
  }

  button {
    position: relative;
    font-weight: 500;

    span {
      padding: 0 12px;
    }

    #minus,
    #plus {
      position: absolute;
    }

    #minus {
      left: 0;
    }

    #plus {
      right: 0;
    }
  }
`;

export const PriceInfo = styled.div`
  padding-top: 1rem;

  dl {
    display: flex;
    align-items: center;
    justify-content: space-between;

    dt {
      font-weight: 500;
    }

    dd {
      font-weight: 700;

      &.cartTotal {
        font-size: medium;
      }

      &.untilFreeDelivery {
        color: teal-400;
      }

      svg {
        transform: scale(1.5);
      }
    }
  }
`;
