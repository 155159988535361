import { Box } from '@xstyled/styled-components';
import { HorizontalChevron } from '@/UI/Icons';

export default function Dismiss({ onClick, ariaLabel, ...rest }) {
  return (
    <Box
      as="span"
      role="button"
      cursor="pointer"
      aria-label={ariaLabel}
      onClick={onClick}
      {...rest}
    >
      {HorizontalChevron}
    </Box>
  );
}
