import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { isIOS } from 'react-device-detect';
import { useUpdateAtom } from 'jotai/utils';
import { resetPasswordModalAtom } from 'global/store';
import useLogin from 'hooks/auth/useLogin';
import useSignup from 'hooks/auth/useSignup';
import useSocialMediaAuth from 'hooks/auth/useSocialMediaAuth';
import styled, { Box } from '@xstyled/styled-components';
import Button from '@/UI/Button';
import Spacer from '@/UI/Primitives/Spacer';
import PrimaryButton from '@/UI/Button/Variants/Primary';
import SocialMediaButton from '@/UI/Button/SocialMedia';
import Modal from '@/UI/Modal';
import Input from '@/UI/Form/Input';
import Spinner from '@/UI/Loader/Spinner';
import { FlexBox, FlexColCenter } from '@/UI/Primitives/FlexBox';
import { SectionTitle } from '@/UI/Primitives/Headings';
import { useTranslation } from 'next-i18next';

export default function AuthModal({ isShown, toggle }) {
  const { t } = useTranslation();

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors
  } = useForm();

  const fields = watch();

  const [authType, setAuthType] = useState('login');
  const isLogin = authType === 'login';

  const setTogglePasswordReset = useUpdateAtom(resetPasswordModalAtom);

  // Login
  const { onLoginSubmit, loading: loginLoading } = useLogin({
    toggleModal: toggle,
    setError
  });

  // Signup
  const { onSignupSubmit, loading: signupLoading } = useSignup({
    toggleModal: toggle,
    fields,
    setError,
    clearErrors
  });

  useEffect(() => {
    if (isShown) {
      setAuthType('login');
      reset();
    }
  }, [isShown, reset]);

  const modalContent = (
    <FlexColCenter
      as="form"
      onSubmit={handleSubmit(isLogin ? onLoginSubmit : onSignupSubmit)}
    >
      <SectionTitle pb="3rem">
        {isLogin ? t('auth.login') : t('auth.create_account')}
      </SectionTitle>

      <SocialMediaButtons toggle={toggle} />

      <FlexBox dir="column" w="80%" py="3rem" spaceY="1.5rem">
        {!isLogin && (
          <>
            <Input
              placeholder={t('forms_data.firstname')}
              {...register('firstname', {
                required: 'Kohustuslik väli'
              })}
              error={errors.firstname}
            />
            <Input
              placeholder={t('forms_data.lastname')}
              {...register('lastname', {
                required: 'Kohustuslik väli'
              })}
              error={errors.lastname}
            />
          </>
        )}
        <Input
          type="email"
          placeholder={t('forms_data.email')}
          {...register('email', {
            required: t('checkout.required')
          })}
          error={errors.email}
        />
        <Box pt={!isLogin && '1rem'} spaceY="1.5rem">
          <Input
            type="password"
            placeholder={t('forms_data.password')}
            {...register('password', { required: t('checkout.required')})}
            error={errors.password}
          />
          {!isLogin && (
            <Input
              type="password"
              placeholder={t('forms_data.repeat_password')}
              {...register('repeatPassword', { required: t('checkout.required')})}
              error={errors.repeatPassword}
            />
          )}
        </Box>
      </FlexBox>
      {isLogin ? (
        <FlexBox dir="column" w="80%" spaceY="0.5rem" pb="3rem">
          <PrimaryButton type="submit" h="3rem">
            {loginLoading ? <Spinner /> : t('auth.login')}
          </PrimaryButton>
          <Button
            hoverTextDecoration="underline"
            onClick={() => {
              toggle();
              setTogglePasswordReset(true);
            }}
          >
            {t('auth.forgot_password')}
          </Button>
        </FlexBox>
      ) : (
        <PrimaryButton type="submit" w="80%" h="2.5rem">
          {signupLoading ? <Spinner /> : 'Registreeru'}
        </PrimaryButton>
      )}
      {!isLogin && <Spacer />}
      <Button
        color="black"
        fontWeight="600"
        textDecoration="underline"
        hoverColor="gray-100"
        onClick={() => setAuthType(isLogin ? 'signup' : 'login')}
      >
        {isLogin
          ? t('auth.register_new_user')
          : t('auth.already_registered_login_here')}
      </Button>
    </FlexColCenter>
  );

  return (
    <Modal
      isShown={isShown}
      hide={toggle}
      content={modalContent}
      width={{ _: '90vw', sm_tablet: '500px', tablet: '590px' }}
      p={{ _: '3rem 1rem', desktop: '3rem 4rem' }}
      h={{ _: !isLogin && (isIOS ? '80vh' : '88vh'), desktop: '100%' }}
    />
  );
}

const Buttons = styled(FlexBox)`
  flex-direction: column;

  @media (max-width: 375px) {
    width: 90%;

    button span {
      font-size: xxsmall;
    }
  }
`;

const SocialMediaButtons = ({ toggle }) => {
  const openPopup = useSocialMediaAuth(toggle);

  return (
    <Buttons spaceY="0.5rem">
      <SocialMediaButton
        name="Facebook"
        onClick={() => openPopup('facebook')}
      />
      <SocialMediaButton name="Google" onClick={() => openPopup('google')} />
    </Buttons>
  );
};
