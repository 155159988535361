import styled, { useTheme } from '@xstyled/styled-components';
import useCouponCode from 'hooks/cart/useCouponCode';
import Input from '@/UI/Form/Input';
import Button from '@/UI/Button';
import PrimaryButton from '@/UI/Button/Variants/Primary';
import { VerticalChevron } from '@/UI/Icons/';
import Spinner from '@/UI/Loader/Spinner';
import { useTranslation } from 'next-i18next';

const CodeWrapper = styled.div`
  width: 250px;
  height: 80px;
  margin-top: 1rem;

  button span {
    position: relative;
    left: 2.5rem;
    bottom: 0.1rem;

    svg {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
      transition: transform 0.3s ease-out;
    }
  }
`;

const CodeForm = styled.form`
  display: flex;
  align-items: flex-start;
  width: 320px;
  margin-top: 1rem;

  input {
    width: 90%;
    height: 40px;
    padding: 12px 0;
    border: 0.8px solid #a8a9ba;
    border-radius: 7px;
  }

  @media (max-width: sm_mobile) {
    width: 280px;
  }
`;

/**
 * CouponCode
 *
 * Coupon code form
 *
 * @prop {boolean} cartOpen - is cart in open state?
 */

export default function CouponCode({ cartOpen }) {
  const { t } = useTranslation();

  const theme = useTheme();

  const { isOpen, toggle, applyCoupon, loading, register, error } =
    useCouponCode(cartOpen);

  return (
    <CodeWrapper isOpen={isOpen}>
      <Button onClick={toggle}>
        % {t('ui.discount_code')}
        <span role="img" aria-label="Toggle promo code form">
          <VerticalChevron stroke={theme.colors['purple-200']} />
        </span>
      </Button>
      {isOpen && (
        <CodeForm>
          <Input {...register('code')} error={error} />
          <PrimaryButton
            p="0.5rem 2rem"
            ml="1rem"
            onClick={() => applyCoupon()}
          >
            {loading ? <Spinner /> : t('ui.apply')}
          </PrimaryButton>
        </CodeForm>
      )}
    </CodeWrapper>
  );
}
