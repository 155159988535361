export default function removeFromCartEvent(product) {
  const price = product?.price_range?.maximum_price;
  return gtag('event', 'remove_from_cart', {
    currency: 'EUR',
    value: price?.final_price?.value,
    items: [
      {
        currency: 'EUR',
        quantity: 1,
        item_name: product?.name,
        item_category: product?.categories[0]?.name,
        item_category2: product?.categories[1]?.name,
        item_category3: product?.categories[2]?.name,
        price: price?.final_price?.value,
        discount: price?.discount?.amount_off
      }
    ]
  });
}
