import { useQuery } from '@apollo/client';
import { GET_VARIATION_IMAGE } from 'graphql/queries';

import { useRouter } from 'next/router';

export default function getCartImage({ mainImage, gallery, sku, variableUid }) {
  const router = useRouter();
  const lang = router.locale ? router.locale : 'et';
  let image = { label: mainImage?.label };

  if (mainImage?.url?.includes('placeholder') && gallery?.length) {
    image = { ...image, url: gallery[0]?.url };
  } else {
    image = { ...image, url: mainImage?.url };
  }

  if (variableUid) {
    const { data, loading } = useQuery(GET_VARIATION_IMAGE, {
      variables: { sku: sku },
      context: { fetchOptions: { method: 'GET' }, headers: { store: lang } }
    });

    if (data && data.products) {
      if (data.products.items.length > 0) {
        const filteredItem = data.products.items[0].variants.filter(
          el => el.product.uid === variableUid
        );
        if (filteredItem.length > 0) {
          image = { ...image, url: filteredItem[0].product.image.url };
        }
      }
    }
    return image;
  } else {
    return image;
  }
}
