import { useEffect, useState } from 'react';
import { useUpdateAtom } from 'jotai/utils';
import Cookies from 'universal-cookie';
import { authAtom } from 'global/store';
import { CLIENT, USER_TOKEN } from 'global/constants';

export default function useSocialMediaAuth(toggle) {
  const [popup, setPopup] = useState(null);

  const openPopup = provider => {
    setPopup(
      window.open(
        `${CLIENT}/api/auth/${provider}`,
        `Baltbaby ${provider} login`,
        'scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=800,height=700'
      )
    );
  };

  const closePopup = () => {
    popup.close();
    setPopup(null);
  };

  const setAuth = useUpdateAtom(authAtom);

  useEffect(() => {
    let interval;
    const cookies = new Cookies();

    if (popup) {
      interval = setInterval(() => {
        const token = cookies.get(USER_TOKEN);

        if (token) {
          setAuth({ token });
          closePopup();
          toggle();
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [popup]);

  return openPopup;
}
