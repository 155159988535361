import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useAtom } from 'jotai';
import {
  cartSidebarAtom,
  accountFlyoutAtom,
  authAtom,
  authModalAtom
} from 'global/store';
import { Box } from '@xstyled/styled-components';
import useHover from 'hooks/UI/useHover';
import useClickOutside from 'hooks/UI/useClickOutside';
import useCartData from 'hooks/cart/useCartData';
import Cart from '@/Cart';
import LanguageSelector from '@/Header/LanguageSelector';
import AccountMenu from '@/Header/Menus/AccountMenu';
import MobileFlyout from '@/Header/Menus/AccountFlyout';
import { LogoLink, CartButton, AccountButton } from './Links';
import SearchBar from '@/Search/SearchBar';
import AuthModal from '@/Modals/Auth';
import ResetPasswordModal from '@/Modals/ResetPassword';
// import { CompareLarge } from '@/UI/Button/Compare';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import { Container } from '@/UI/Layout/Container';
import { HorizontalChevron } from '@/UI/Icons';
import { HamburgerButton, MobileNav } from './styles';

export default function Navbar() {
  const router = useRouter();

  const cart = useCartData();

  const [{ token: isAuth }] = useAtom(authAtom);
  const [isCartOpen, setCartOpen] = useAtom(cartSidebarAtom);
  const [authModalOpen, setAuthModalOpen] = useAtom(authModalAtom);

  const [isAccountFlyoutOpen, setAccountFlyoutOpen] =
    useAtom(accountFlyoutAtom);
  const [isAccountDropdownOpen, setAccountDropdownOpen] = useState(false);

  const [isHovered, bind] = useHover();

  const toggleCart = () => setCartOpen(!isCartOpen);

  const accountMenuRef = useRef(null);
  useClickOutside(accountMenuRef, () => setAccountDropdownOpen(false));

  return (
    <Box as="nav" h="88px" m={{ desktop: '2.125rem 0' }}>
      {/* Mobile navigation */}
      <MobileNav>
        {!router.pathname.endsWith('search') ? (
          <HamburgerButton
            aria-label="Open navigation menu"
            onClick={() => router.push('/menu')}
          >
            <span />
            <span />
            <span />
          </HamburgerButton>
        ) : (
          <span
            role="button"
            aria-label="Close shopping cart"
            onClick={toggleCart}
          >
            {HorizontalChevron}
          </span>
        )}

        <LogoLink />

        <FlexBox align="flex-end" spaceX="1rem" mt="0.6rem">
          <AccountButton
            onClick={
              isAuth
                ? () => setAccountFlyoutOpen(!isAccountFlyoutOpen)
                : () => setAuthModalOpen(!authModalOpen)
            }
          />
          <CartButton quantity={cart?.total_quantity} onClick={toggleCart} />
        </FlexBox>
      </MobileNav>

      {/* Account flyout menu for mobile */}
      {isAccountFlyoutOpen && <MobileFlyout />}

      {/* -------------------------------- */}

      {/* Desktop navigation */}
      <Container display={{ _: 'none', desktop: 'block' }}>
        <FlexBox justify="space-between">
          <LogoLink />

          <FlexBox>
            <SearchBar />
            <LanguageSelector />

            {/* Toggle buttons with icons */}
            <FlexBox align="center" spaceX="1rem" ml="4rem">
              <AccountButton
                isActive={isAccountDropdownOpen}
                onClick={
                  isAuth
                    ? () => setAccountDropdownOpen(!isAccountDropdownOpen)
                    : () => setAuthModalOpen(!authModalOpen)
                }
              />
              <Box {...bind}>
                <CartButton
                  quantity={cart?.total_quantity}
                  isActive={isCartOpen}
                  onClick={toggleCart}
                />
              </Box>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        {/* Account dropdown menu for desktop */}
        {isAccountDropdownOpen && (
          <AccountMenu
            ref={accountMenuRef}
            toggleMenu={() => setAccountDropdownOpen(false)}
          />
        )}

        {/* Product comparison indicator button */}
        {/* <FlexBox
          display={{ _: 'none', desktop: 'flex' }}
          dir="column"
          align="flex-end"
          mt="1.5rem"
        >
          <CompareLarge />
        </FlexBox> */}
      </Container>

      {/* Shopping cart */}
      <Cart isHovered={isHovered} />

      {/* Auth modal */}
      <AuthModal
        isShown={authModalOpen}
        toggle={() => setAuthModalOpen(!authModalOpen)}
      />

      {/* Reset password modal */}
      <ResetPasswordModal />
    </Box>
  );
}
