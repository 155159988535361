import getPrice from './getPrice';
import getFormattedPrice from './getFormattedPrice';
export default function getCartProductData(item) {

  const priceWithTax = item?.prices?.row_total_including_tax?.value;
  const altPrice = getPrice(
    item?.product?.price_range?.minimum_price,
    item?.product?.price
  );
  const price = getFormattedPrice(
    priceWithTax ?? altPrice?.finalPrice ?? altPrice
  );

  const selectedVariants = item?.configurable_options?.map(
    item => item.value_label
  );

  return { price, selectedVariants };
}
