import { useMemo, useState } from 'react';

export default function useHover() {
  const [isHovered, setHovered] = useState(false);

  const bind = useMemo(
    () => ({
      onMouseEnter: () => setHovered(true),
      onMouseLeave: () => setHovered(false)
    }),
    []
  );

  return [isHovered, bind];
}
