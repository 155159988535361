import { useUpdateAtom } from 'jotai/utils';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { useMutation } from '@apollo/client';
import { GENERATE_TOKEN } from 'graphql/mutations';
import { authAtom } from 'global/store';
import { USER_TOKEN } from 'global/constants';
import loginEvent from 'lib/Google/events/login.gtag';
import { useTranslation } from 'next-i18next';


export default function useLogin({ toggleModal, setError }) {
  const setAuth = useUpdateAtom(authAtom);
  const { t } = useTranslation();

  const setManualError = field =>
    setError(field, {
      type: 'manual',
      message: t('ui.check_data')
    });

  const [generateToken, { loading, error }] = useMutation(GENERATE_TOKEN, {
    onCompleted: data => {
      loginEvent(); // Send login event to Google Analytics

      const {
        generateCustomerToken: { token }
      } = data;

      const cookies = new Cookies();
      const expires = dayjs().add(1, 'd').toDate();
      cookies.set(USER_TOKEN, token, { path: '/', expires });

      setAuth({ token });
      toggleModal();
    },
    onError: error => {
      if (error.message.includes('sign-in was incorrect')) {
        setManualError('email');
        setManualError('password');
      }
    }
  });

  const onLoginSubmit = data => {
    generateToken({
      variables: { email: data.email, password: data.password }
    });
  };

  return { onLoginSubmit, loading: loading && !error };
}
