import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { SEARCHED_PRODUCTS } from 'graphql/queries';
import getSearchTerm from 'utils/getSearchTerm';
import useClickOutside from 'hooks/UI/useClickOutside';
import styled, { Box } from '@xstyled/styled-components';
import Dropdown from './Dropdown';
import ClientOnly from '@/UI/Primitives/ClientOnly';
import { Search } from '@/UI/Icons/Header';
import { useTranslation } from 'next-i18next';

const SearchInput = styled.inputBox`
  width: 81vw;
  border: none;
  border-bottom: 2px solid #a8a9ba;
  background-color: transparent;
  cursor: pointer;
  caret-color: #d0d0d0;

  &:active,
  &:focus {
    outline: none;
  }

  ::placeholder {
    font-size: xxsmall;
    text-align: center;
  }

  @media (min-width: 455px) {
    width: 400px;
  }

  @media (min-width: desktop) {
    height: 30px;
    margin-top: -2rem;
  }
`;

const SearchBarWrapper = styled(ClientOnly)`
  position: relative;

  span svg {
    position: absolute;
    right: 4;
  }

  @media (min-width: desktop) {
    margin-left: 2rem;
  }
`;

export default function SearchBar({isSearchPage}) {

  const router = useRouter();
  const { t } = useTranslation();
  const lang = router.locale ? router.locale : 'et';

  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(true);
  const [searchResults, setSearchResults] = useState(null);

  const handleFocus = () => searchResults && !isSearchPage && setShowDropdown(true);
  const handleChange = event => setSearchTerm(event.target.value);
  const handleEnter = event =>
    searchTerm && event.charCode === 13 && router.push(`/otsing/${searchTerm}`);

  const { data, loading } = useQuery(SEARCHED_PRODUCTS, {
    skip: searchTerm.length < 4,
    variables: { name: getSearchTerm(searchTerm) },
    context: { fetchOptions: { method: 'GET' }, headers: {store: lang} }
  });

  const searchRef = useRef(null);
  useClickOutside(searchRef, () => setShowDropdown(false));

  useEffect(() => {
    if (searchResults?.length || loading ) {
      if(isSearchPage) {
      setShowDropdown(false);
      } else {
        setShowDropdown(true)
      }
    } else {
      setShowDropdown(false);
    }
  }, [searchResults, loading, isSearchPage]);

  useEffect(() => {
    if (data?.products?.items) {
      setSearchResults(data?.products?.items);
    }
    if (searchTerm.length < 4) {
      setSearchResults(null);
    }
  }, [data?.products?.items, searchTerm]);

  return (
    <Box ref={searchRef}>
      <SearchBarWrapper>
        <SearchInput
          type="text"
          name="searchTerm"
          value={searchTerm}
          placeholder={t('search.bar_placeholder')}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={handleEnter}
        />
        <Box
          as="span"
          role="button"
          aria-label="Magnifying glass representing search"
          cursor="pointer"
          onClick={() => searchTerm && router.push(`/otsing/${searchTerm}`)}
        >
          {Search}
        </Box>
        {showDropdown && (
          <Dropdown
            searchTerm={searchTerm}
            searchResults={searchResults}
            setShowDropdown={setShowDropdown}
            loading={loading}
          />
        )}
      </SearchBarWrapper>
    </Box>
  );
}
