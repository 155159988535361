import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { useUpdateAtom } from 'jotai/utils';
import { useViewportWidth } from '@xstyled/styled-components';
import { accountFlyoutAtom, authAtom } from 'global/store';
import {
  AUTH_CART_INIT,
  CART_ID,
  USER_SOCIAL_AUTH,
  USER_TOKEN
} from 'global/constants';

export default function useLogout(toggleMenu) {
  const router = useRouter();
  const cookies = new Cookies();

  const viewportWidth = useViewportWidth();
  const desktop = viewportWidth >= 1024;

  const setAuth = useUpdateAtom(authAtom);
  const setFlyoutOpen = useUpdateAtom(accountFlyoutAtom);

  const toggle = () => (desktop ? toggleMenu() : setFlyoutOpen(false));

  const logout = () => {
    cookies.remove(USER_TOKEN, { path: '/' });
    cookies.remove(USER_SOCIAL_AUTH, { path: '/' });
    cookies.remove(CART_ID, { path: '/' });
    cookies.remove(AUTH_CART_INIT, { path: '/' });

    setAuth({ token: null });
    toggle();
    router.push('/');
  };

  return logout;
}
