import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { CART_ID } from 'global/constants';
import { CREATE_NEW_CART } from 'graphql/mutations';

export default function useCreateLocaleCart() {
  const cookies = new Cookies();

  const [createCart] = useMutation(CREATE_NEW_CART, {
    onCompleted: data => {
      const cart_id = data?.createEmptyCart;
      // Set to expire in 1 day
      const expires = dayjs().add(1, 'd').toDate();
      // Set as cookie
      cookies.set(CART_ID, cart_id, { path: '/', expires });
    }
  });

  return createCart;
}